import { AnimatePresence, motion } from "framer-motion";
import profile from "../assets/profile.png";
import { BsFillLaptopFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";
import cv from "../assets/cv.pdf"
import { saveAs } from "file-saver";

const About = () => {
  const [selectedContainer, setSelectedContainer] = useState(null);

  const about_info = [
    {
      id: 1,
      img: <BsFillLaptopFill />,
      name: "Experience",
      value: "3 + years",
      more_info: <div className="text-black">temp1</div>,
    },
    {
      id: 2,
      img: <BsFillLaptopFill />,
      name: "Completed",
      value: "15 + projects",
      more_info: <div className="text-black">temp2</div>,
    },
    {
      id: 3,
      img: <BsFillLaptopFill />,
      name: "Rank",
      value: "Associate Software Engineer",
      more_info: <div className="text-black">temp3</div>,
    },
  ];

  const container_config = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item_config = {
    hidden: { y: 0, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleDownloadCV = () => {
    saveAs(cv, "gihan_sudeepa.pdf");
  }

  return (
    <div className="absolute font-michroma  w-[100%] h-full  overflow-y-auto ">
      <div className="ml-[45px]  z-20">
        <motion.h1
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          className="text-[25px] pt-[40px] font-michroma"
        >
          ABOUT
        </motion.h1>

        <motion.div
          variants={{
            hidden: { opacity: 0, x: 0 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          className="w-3/4 mb-[120px] md:mb-[0px] rounded-[10px] mt-10 p-8 md:flex-row flex-col  backdrop-blur-sm bg-white/50  inset-0 flex  "
        >
          <motion.img
            variants={{
              hidden: { opacity: 0, x: 0 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{
              duration: 1,
              delay: 1.5,
            }}
            src={profile}
            className="w-[300px] h-1/2 rounded-[10px]"
          />
          <div className="md:ml-[25px] ml-0">
            <motion.div
              variants={container_config}
              transition={{
                duration: 3,
                delay: 1.5,
              }}
              initial="hidden"
              animate="visible"
              className="container_config justify-between relative flex flex-col md:flex-row   "
            >
              {about_info.map((index) => (
                <motion.div
                  key={index.id}
                  variants={item_config}
                  layoutId={index.id}
                  onClick={() => setSelectedContainer(index)}
                  transition={{
                    duration: 1,
                    delay: 1.5,
                  }}
                  className="item_config w-full rounded-[10px] mt-5 md:mt-0 bg-white justify-center items-center flex flex-col text-black md:w-[180px] h-[120px]"
                >
                  <BsFillLaptopFill className="fill-gray-500" size={30} />
                  <spam className="font-semibold">{index.name}</spam>
                  <span className="text-[12px] text-gray-600">
                    {index.value}
                  </span>
                </motion.div>
              ))}

              {/* <AnimatePresence>
                {selectedContainer && (
                  <motion.div
                    layoutId={selectedContainer.id}
                    className=" absolute w-full rounded-[10px]  p-8  h-full bg-white"
                  >
                    <MdOutlineCancel
                      onClick={() => setSelectedContainer(null)}
                      className="absolute right-6 fill-gray-500"
                      size={25}
                    />
                    {selectedContainer.more_info}
                  </motion.div>
                )}
              </AnimatePresence> */}
            </motion.div>

            <div className=" mt-9 ">
              <div>
                Hello, I'm Gihan Sudeepa, a driven undergraduate in Software
                Engineering, fueled by a deep passion for continuous learning
                and exploring cutting-edge technologies.
              </div>
              <button onClick={handleDownloadCV} className="bg-gray-700 mt-5 p-4 bottom-2 rounded-[10px]">
                DOWNLOAD CV
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
