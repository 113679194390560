export const Skill_data = [
  {
    skill_name: "Html 5",
    Image:
      "c++.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "java.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "kotlin.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "js.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "ts.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "python.png",
    width: 80,
    height: 80,
  },
];

export const Socials = [
  {
    skill_name: "Html 5",
    Image:
      "",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "",
    width: 80,
    height: 80,
  },
];

export const Frontend_skill = [
  {
    skill_name: "Html 5",
    Image:
      "springboot.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "express-js.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "reactjs.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "nestjs.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "nextjs.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "vertx.png",
    width: 80,
    height: 80,
  },
];

export const Backend_skill = [
  {
    skill_name: "Html 5",
    Image:
      "sql_lite.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "mysql.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "mongodb.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "firebase.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "postgresql.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "oracle.png",
    width: 80,
    height: 80,
  },
];

export const Full_stack = [
  {
    skill_name: "Html 5",
    Image:
      "github_actions.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "docker.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "rabbitmq.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "kafka.png",
    width: 80,
    height: 80,
  },
  {
    skill_name: "Html 5",
    Image:
      "kubernetes.png",
    width: 80,
    height: 80,
  },
];

export const Other_skill = [
    {
        skill_name: "Html 5",
        Image: "azure.png",
        width: 80,
        height: 80,
      },    {
        skill_name: "Html 5",
        Image: "aws.png",
        width: 80,
        height: 80,
      },    {
        skill_name: "Html 5",
        Image: "datadog.png",
        width: 80,
        height: 80,
      },
];
