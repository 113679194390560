import { motion } from "framer-motion";
import { useRef, useState } from "react";
import login from "../assets/logoin.png";
import logout from "../assets/logout.png";
import start from "../assets/start.png";

const Spinner = (props) => {
  const [test, setTest] = useState(true);
  const audioRef = useRef(null);

  const playPauseHandler = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const pingwhatapp = () => {
    const browserInfo = {
      userAgent: navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    const locationInfo = {
      href: window.location.href,
      hostname: window.location.hostname,
      pathname: window.location.pathname,
    };

    const url = "https://api.callmebot.com/whatsapp.php";
    const phone = "94710816191";
    const text = `${ "Yo someone visited the site  : "+ JSON.stringify(locationInfo)} + ${JSON.stringify(browserInfo)}`;
    const apiKey = "3088180";

    fetch(
      `${url}?phone=${phone}&text=${encodeURIComponent(text)}&apikey=${apiKey}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("API call successful!");
      })
      .catch((error) => {
        console.error("There was a problem with the API call:", error.message);
      });
  };

  return (
    <>
      <audio ref={audioRef} src="start.mp3" />

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 0 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={test ? "visible" : "hidden"}
        transition={{
          duration: 1,
          delay: 1.5,
        }}
        className="flex bg-whote z-20 bg-black items-center justify-center h-screen"
      >
        {/* <div className={`absolute bottom-0 bg-white h-2 w-[${props.progress}%]`}></div>
         */}
        <motion.div
          className="text-center relative"
          variants={{
            hidden: { opacity: 0, y: 0 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
        >
          <div className="flex relative items-center justify-center h-screen">
            <motion.img className="w-[150px]" src={logout} alt="Logout" />
            <motion.img
              className="w-[95px] absolute"
              src={start}
              alt="start"
              onClick={() => {
                pingwhatapp();
                playPauseHandler();
                setTest(false);
                props.setLoading();
              }}
            />
            <motion.img
              className="w-[95px] absolute"
              src={login}
              alt="Login"
              variants={{
                visible: { opacity: 1, y: 0 },
                open: { opacity: 1, y: 75 },
              }}
              initial="visible"
              animate={props.progress == 100 ? "open" : "visible"}
              transition={{
                duration: 2,
                delay: 2,
              }}
            />
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Spinner;
