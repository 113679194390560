import { motion } from "framer-motion";
import { useState } from "react";
import {
  Backend_skill,
  Frontend_skill,
  Full_stack,
  Other_skill,
  Skill_data,
} from "../constants/skills";

const Skills = () => {
  const [selectedContainer, setSelectedContainer] = useState(null);

  return (
    <div className="absolute z-10 font-michroma w-full h-full overflow-y-auto ">
      <div className="mx-[45px] z-20">
        <motion.h1
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          className="text-[25px] pt-[40px] font-michroma"
        >
          SKILLS
        </motion.h1>

        <motion.div
          className="mb-[70px]"
        >
          <section
            id="skills"
            className="flex flex-col items-center justify-center gap-3  relative overflow-hidden  "
          >
            <div className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
              {Skill_data.map((image, index) => (
                <SkillDataProvider
                  key={index}
                  src={image.Image}
                  width={image.width}
                  height={image.height}
                  index={index}
                />
              ))}
            </div>

            <div className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
              {Frontend_skill.map((image, index) => (
                <SkillDataProvider
                  key={index}
                  src={image.Image}
                  width={image.width}
                  height={image.height}
                  index={index}
                />
              ))}
            </div>
            <div className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
              {Backend_skill.map((image, index) => (
                <SkillDataProvider
                  key={index}
                  src={image.Image}
                  width={image.width}
                  height={image.height}
                  index={index}
                />
              ))}
            </div>
            <div className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
              {Full_stack.map((image, index) => (
                <SkillDataProvider
                  key={index}
                  src={image.Image}
                  width={image.width}
                  height={image.height}
                  index={index}
                />
              ))}
            </div>
            <div className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
              {Other_skill.map((image, index) => (
                <SkillDataProvider
                  key={index}
                  src={image.Image}
                  width={image.width}
                  height={image.height}
                  index={index}
                />
              ))}
            </div>

          </section>
        </motion.div>
      </div>
    </div>
  );
};

const SkillDataProvider = ({ src, width, height, index }) => {
  const [show, setShow] = useState(true);
  const [showSkill, setShowSkill] = useState(false);

  const imageVariants = {
    hidden: {
      opacity: 0,
      rotateY: 180,
      transition: { delay: (index + 1) * 0.5, duration: 0.5 },
    },
    visible: {
      opacity: 1,
      rotateY: 0,
      transition: { delay: (index + 1) * 0.5, duration: 0.5 },
    },
    rotate: {
      opacity: 1,
      rotateY: 360,
      transition: { delay: 0.3, duration: 0.5 },
    },
  };

  return (
    <motion.div
      onClick={() => setShow(!show)}
      initial="hidden"
      variants={imageVariants}
      animate={show ? "visible" : "rotate"}
      custom={index}
      className="flex z-40 flex-col  relative items-center justify-center p-1 backdrop-blur-sm   rounded-full"
    >
      <img
        src={src}
        width={width}
        height={height}
        alt="skill image"
        className="rounded-full"
      />
     {!show && (
        <motion.div
        variants={{
          hidden: { opacity: 0, y: 0 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={"visible"}
        transition={{
          duration: 1,
          delay: 0.2,
        }}
        className="absolute stroke-cyan-500  text-[12px] flex items-center justify-center rounded-full w-full h-full backdrop-blur-sm bg-white/60">
          <span className="capitalize backdrop-blur-sm bg-black/50 p-[2px] rounded-2xl ">
          {src.replace(/\.png$/, '')}
            </span>  
        </motion.div>
     )}
    </motion.div>
  );
};
export default Skills;
