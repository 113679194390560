import React, { useEffect, useRef, useState } from "react";
import { FaVolumeXmark } from "react-icons/fa6";
import { FaVolumeUp } from "react-icons/fa";

const Songplayer = () => {
  const audioRef = useRef(null);
  const [sound, setSound] = useState(true);

  const playPauseHandler = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  useEffect(() => {
    playPauseHandler();
  }, []);

  return (
    <>
      <audio ref={audioRef} src="song.opus" />

      <div className="absolute bottom-10 z-40 left-4 text-[10px]">
        {sound ? <FaVolumeUp onClick={()=>{
          setSound(false);
          playPauseHandler()
        }} size={30} /> : <FaVolumeXmark
        onClick={()=>{
          setSound(true);
          playPauseHandler()
        }}
        size={30} />}

        <span className="text-[20px] hidden md:block  font-bold">
          Song: Nine thou instrumental{" "}
        </span>
        <br />
        <span className="text-[13px] hidden md:block  ">Artist: Styles Of Beyond</span>
      </div>
    </>
  );
};

export default Songplayer;
