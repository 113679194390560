import { AnimatePresence, motion } from "framer-motion";
import profile from "../assets/profile.png";
import { BsFillLaptopFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";

const Connect = () => {
  return (
    <div className= " absolute z-10 font-michroma w-full h-full overflow-y-auto ">
      <div className="  z-20">
        <motion.h1
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          className=" uppercase mx-[45px] text-[25px] pt-[40px] font-michroma"
        >
          Let's Connect
        </motion.h1>

        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          name="contact"
          className="bg-white/60 mb-[70px]  md:mx-auto mx-[20px] max-w-[500px] mt-[70px]  rounded-[10px] flex justify-center items-center p-4"
        >
          <form
            method="POST"
            action="https://getform.io/f/cf25f5c1-e150-4b5e-9de6-29b032e09eee"
            className="flex flex-col max-w-[600px] w-full"
          >
            <input
              className="text-black bg-[#ffffff] p-2 rounded-lg"
              type="text"
              placeholder="Name"
              name="name"
              required
            />
            <input
              className="text-black my-4 p-2 bg-[#ffffff] rounded-lg"
              type="email"
              placeholder="Email"
              name="email"
              required
            />
            <textarea
              className="text-black bg-[#ffffff] p-2 rounded-lg"
              name="message"
              rows="10"
              placeholder="Message"
            ></textarea>
            <button className=" text-white border-2 hover:bg-[#c2c2c2] hover:border-[#ffffff] rounded-lg w-[100px]  px-4 py-3 my-8 mx-auto flex ">
              Submit
            </button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

const SkillDataProvider = ({ src, width, height, index }) => {
  const [show, setShow] = useState(true);
  const [showSkill, setShowSkill] = useState(false);

  const imageVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <motion.div
      initial="hidden"
      variants={imageVariants}
      animate={"visible"}
      custom={index}
      className="flex z-40 flex-col  relative items-center justify-center p-1 backdrop-blur-sm    rounded-full"
    >
      <img
        src={src}
        width={width}
        height={height}
        alt="skill image"
        className="rounded-full"
      />
    </motion.div>
  );
};
export default Connect;
