

import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";
import { project_data } from "../constants/project";

const Projects = () => {
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [finished, setFinished] = useState(false);
  const itemsPerPage = 4;


  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = project_data.slice(firstItemIndex, lastItemIndex);
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedContainer(null); 
  };

  return (
    <div className="absolute font-michroma left-0 w-full h-full overflow-y-auto">
      <div className="mx-[45px] h-screen z-20">
        <motion.h1
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          className="text-[25px] pt-[40px] font-michroma"
        >
          PROJECTS
        </motion.h1>

        <motion.div className="mx-[100px] mt-[70px]  justify-center rounded-[10px] p-8 backdrop-blur-sm flex">
          <div className="w-full h-full flex items-center justify-center">
            <motion.div
        
              initial="hidden"
              animate="visible"
              className="  flex flex-wrap justify-around"
            >
              {currentItems.map((index) => (
                <motion.div
                  key={index.id}
                  variants={{
                    hidden: { opacity: 0, x: 0 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  layoutId={index.id}
                  onClick={() => setSelectedContainer(index)}
                  transition={{
                    duration: 1,
                    delay:  !finished ? 0.1 : 0.1 * index.id,
                  }}
                  onAnimationComplete={() => setFinished(true)}
                  className="m-1  item_config w-[280px] h-[280px] mx-1 col-span-1 rounded-[10px] bg-white flex flex-col text-black"
                >
                  <motion.img
                    initial="hidden"
                    animate="visible"
                    transition={{
                      duration: 1,
                      delay: 1.5,
                    }}
                    src={index.img}
                    className="w-full h-[240px] p-3 rounded-[10px]"
                  />
                  <div className="px-3 pb-3">
                    <div className="font-semibold">{index.name}</div>
                  </div>
                </motion.div>
              ))}
            </motion.div>

            <AnimatePresence>
              {selectedContainer && (
                <motion.div
                  layoutId={selectedContainer.id}
                  className="absolute  rounded-[10px] p-8 md:h-full w-screen md:w-full bg-white"
                >
                  <MdOutlineCancel
                    onClick={() => setSelectedContainer(null)}
                    className="absolute text-black right-6 fill-gray-500"
                    size={25}
                  />

                  <div className="flex flex-col md:flex-row">
                    <motion.img
                      variants={{
                        hidden: { opacity: 0, x: 0 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate="visible"
                      transition={{
                        duration: 1,
                        delay: 0.1,
                      }}
                      src={selectedContainer.img}
                      className=" h-[300px] rounded-[10px]"
                    />
                    <div className="px-5 flex flex-col">
                      <div className="text-black text-2xl font-semibold">
                        {selectedContainer.name}
                      </div>
                      <div className="text-[12px] text-gray-600">
                        {selectedContainer.value}
                      </div>
                      <div className="text-[12px] mt-2 bottom-1 text-gray-600">
                        Skills :{" "}
                        {selectedContainer.skills.map((index) => (
                          <span className="text-[12px] text-gray-600">
                            {" "}
                            [{index}] ,{" "}
                          </span>
                        ))}
                      </div>
                      <div className="text-[12px] text-gray-600 mt-auto">
                        Links :{" "}
                        {selectedContainer.links.map((index) => (
                          <a
                            key={index.name}
                            href={index.link}
                            className="text-[12px] text-gray-600"
                          >
                            {" "}
                            [{index.name}]{" "}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
    
        </motion.div>
        <div className="mt-1 mb-[80px] w-full flex justify-center">
            {[...Array(Math.ceil(project_data.length / itemsPerPage))].map(
              (pageNumber, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`mx-1 p-2 rounded ${
                    currentPage === index + 1 ? "bg-gray-300/70 " : "bg-gray-200/30"
                  }`}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
      </div>
    </div>
  );
};

export default Projects;
