import * as THREE from "three";
import { motion } from "framer-motion";
import { Suspense, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { GoHomeFill } from "react-icons/go";
import { FaTools } from "react-icons/fa";
import { PiToolboxFill } from "react-icons/pi";

import { MdEmail } from "react-icons/md";

import { BsFillPersonLinesFill } from "react-icons/bs";
import {
  PerformanceMonitor,
  AccumulativeShadows,
  RandomizedLight,
  Environment,
  Lightformer,
  Float,
  useProgress,
  Loader,
} from "@react-three/drei";
import { LayerMaterial, Color, Depth } from "lamina";
import { Porsche } from "./model/Porsche";
import Heading from "./components/Heading";
import Songplayer from "./components/Songplayer.jsx";
import About from "./components/About";
import Spinner from "./components/Spinner";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Connect from "./components/Connect";
import Social from "./components/Social";

export function App() {
  const [degraded, degrade] = useState(false);
  const [px, setpx] = useState(8);
  const [py, setpy] = useState(-1);
  const [pz, setpz] = useState(-23);

  const [rx, setrx] = useState(0.1);
  const [ry, setry] = useState(2);
  const [rz, setrz] = useState(0.1);
  const [test, settest] = useState(false);

  const [scale, setscale] = useState(0.8);
  const [activeButton, setActiveButton] = useState("");
  const [targetRotation, setTragetRotation] = useState([0, 0, 0]);

  const [targetPosition, setTargetPosition] = useState([2, -1, 5]);
  const [targetModelRotaion, settargetModelRotaion] = useState([2, -1, 5]);

  const [closeSpinner, setCloseSpinner] = useState(true);

  const { progress } = useProgress();

  const [loading, setLoading] = useState(true);

  const handleButtonClick = (index, amount) => {
    const newTargetPosition = [...targetPosition];
    newTargetPosition[index] += amount;
    setTargetPosition(newTargetPosition);
  };

  const handleButtonClick2 = (index, amount) => {
    const newTargetPosition = [...targetRotation];
    newTargetPosition[index] += amount;
    setTragetRotation(newTargetPosition);
  };

  const changeCamera = (position) => {
    setActiveButton(position);
    let newTargetPosition;
    let newTargetRoation;

    switch (position) {
      case "one":
        newTargetPosition = [2, -1, 5];
        newTargetRoation = [0, 0, 0];
        break;
      case "two":
        newTargetPosition = [4, -1, -3];
        newTargetRoation = [0, 0, 0];
        break;
      case "three":
        newTargetPosition = [4, 1, 1.85];
        newTargetRoation = [-3.95, -2, -9];
        break;
      case "four":
        newTargetPosition = [21, 0, 40.85];
        newTargetRoation = [-3.95, -2, -9];
        break;
      case "five":
        newTargetPosition = [7, 1, 2];
        newTargetRoation = [-3.95, -2, -9];
        break;

      default:
        newTargetPosition = [4, 2, 3];
        newTargetRoation = [-3.95, -2, -9];
    }

    setTargetPosition(newTargetPosition);
    setTragetRotation(newTargetRoation);
  };

  const landing = () => {
    setLoading(false);
    setActiveButton("one");
    setTimeout(() => {
      setCloseSpinner(false);
    }, 3000);
  };

  return (
    <>
      <Canvas
        style={{ height: "100vh" }}
        shadows
        camera={{ position: [5, 0, 15], fov: 30 }}
      >
        <Suspense fallback={null}>
          <spotLight
            position={[0, 15, 0]}
            angle={0.3}
            penumbra={1}
            castShadow
            intensity={2}
            shadow-bias={-0.0001}
          />
          <ambientLight intensity={0.5} />
          <Environment
            frames={degraded ? 1 : Infinity}
            resolution={256}
            background
            blur={1}
          >
            <Lightformers />
          </Environment>

          <Porsche
            scale={scale}
            position={[px, py, pz]}
            rotation={[rx, ry, rz]}
          />
          <AccumulativeShadows
            position={[-4, -1.5, -9]}
            rotation={[0.12, 0.5, 0]}
            frames={100}
            alphaTest={0.3}
            scale={34}
          >
            <RandomizedLight
              intensity={0.6}
              amount={10}
              radius={5}
              ambient={0.5}
              position={[2, 7, -4]}
            />
          </AccumulativeShadows>
          <PerformanceMonitor onDecline={() => degrade(true)} />

          <CameraRig
            targetPosition={targetPosition}
            targetRotation={targetRotation}
          />
        </Suspense>
      </Canvas>

      <div className="absolute top-0 left-0 w-[100%] h-[100%]">
        {!loading && <Songplayer />}

        <div className="absolute z-40 font-michroma top-[230px] md:top-[160px] right-[50px] md:right-[150px] text-sm">
          {/* <div>
            <div>
              <button onClick={() => handleButtonClick(0, 1)}>
                Increase X
              </button>
              <button onClick={() => handleButtonClick(0, -1)}>
                Decrease X
              </button>
            </div>

            <div>
              <button onClick={() => handleButtonClick(1, 1)}>
                Increase Y
              </button>
              <button onClick={() => handleButtonClick(1, -1)}>
                Decrease Y
              </button>
            </div>

            <div>
              <button onClick={() => handleButtonClick(2, 1)}>
                Increase Z
              </button>
              <button onClick={() => handleButtonClick(2, -1)}>
                Decrease Z
              </button>
            </div>

            <p>Target Position: {JSON.stringify(targetPosition)}</p>
          </div> */}

          {/* <div>
            <div>
              <button onClick={() => handleButtonClick2(0, 1)}>
                Increase X
              </button>
              <button onClick={() => handleButtonClick2(0, -1)}>
                Decrease X
              </button>
            </div>

            <div>
              <button onClick={() => handleButtonClick2(1, 1)}>
                Increase Y
              </button>
              <button onClick={() => handleButtonClick2(1, -1)}>
                Decrease Y
              </button>
            </div>

            <div>
              <button onClick={() => handleButtonClick2(2, 1)}>
                Increase Z
              </button>
              <button onClick={() => handleButtonClick2(2, -1)}>
                Decrease Z
              </button>
            </div>

            <p>Target Rotation: {JSON.stringify(targetRotation)}</p>
          </div> */}
          {!loading && (
            <motion.div
              variants={{
                hidden: { opacity: 0, x: -45 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 1,
                delay: 1.5,
              }}
              className=" fixed flex-col "
            >
              <ul className="">
                <li
                  onClick={() => {
                    changeCamera("one");
                  }}
                  className={`flex  my-2 items-center justify-center w-[50px] backdrop-blur-sm ${
                    activeButton === "one" ? "bg-gray-400/60" : ""
                  } inset-0 rounded-[10px] h-[50px]`}
                >
                  <GoHomeFill size={30} />
                </li>
                <li
                  onClick={() => {
                    changeCamera("two");
                  }}
                  className={`flex my-2  items-center justify-center w-[50px] backdrop-blur-sm ${
                    activeButton === "two" ? "bg-gray-400/60" : ""
                  } inset-0 rounded-[10px] h-[50px]`}
                >
                  <BsFillPersonLinesFill size={30} />
                </li>

                <li
                  onClick={() => {
                    changeCamera("three");
                  }}
                  className={`flex  my-2 items-center justify-center w-[50px] backdrop-blur-sm ${
                    activeButton === "three" ? "bg-gray-400/60" : ""
                  } inset-0 rounded-[10px] h-[50px]`}
                >
                  <FaTools size={30} />
                </li>

                <li
                  onClick={() => {
                    changeCamera("four");
                  }}
                  className={`flex my-2  items-center justify-center w-[50px] backdrop-blur-sm ${
                    activeButton === "four" ? "bg-gray-400/60" : ""
                  } inset-0 rounded-[10px] h-[50px]`}
                >
                  <PiToolboxFill size={30} />
                </li>

                <li
                  onClick={() => {
                    changeCamera("five");
                  }}
                  className={`flex items-center justify-center w-[50px] backdrop-blur-sm ${
                    activeButton === "five" ? "bg-gray-400/60" : ""
                  } inset-0 rounded-[10px] h-[50px]`}
                >
                  <MdEmail size={30} />
                </li>
              </ul>
            </motion.div>
          )}
        </div>

        <div
 
          className="z-40 absolute bottom-[70px] md:bottom-10 right-5  text-[15px] "
        >
          {loading
            ? progress == 100
              ? "Enjoy :)"
              : `Loading ${Math.floor(progress)} %`
            : "BMW m8 coupe "}
        </div>
        {activeButton === "one" && <Heading />}
        {activeButton === "two" && <About />}
        {activeButton === "three" && <Skills />}
        {activeButton === "four" && <Projects />}
        {activeButton === "five" && <Connect />}
        {closeSpinner && <Spinner progress={progress} setLoading={landing} />}

        {!loading && <Social />}
      </div>
    </>
  );
}

function CameraRig({ targetPosition, targetRotation }) {
  return useFrame((state) => {
    const t = state.clock.elapsedTime;
    const currentPosition = state.camera.position.clone();

    const lerpedPosition = currentPosition.lerp(
      new THREE.Vector3().fromArray(targetPosition),
      0.05
    );
    const targetRotationVector = new THREE.Vector3().fromArray(targetRotation);
    const lerpedLookAt = targetRotationVector.normalize();
    state.camera.position.copy(lerpedPosition);
    state.camera.lookAt(state.camera.position.clone().add(lerpedLookAt));
  });
}

function Lightformers({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
  const group = useRef();
  useFrame(
    (state, delta) =>
      (group.current.position.z += delta * 10) > 20 &&
      (group.current.position.z = -60)
  );
  return (
    <>
      <Lightformer
        intensity={0.75}
        rotation-x={Math.PI / 2}
        position={[0, 5, -9]}
        scale={[10, 10, 1]}
      />
      <group rotation={[0, 0.5, 0]}>
        <group ref={group}>
          {positions.map((x, i) => (
            <Lightformer
              key={i}
              form="circle"
              intensity={2}
              rotation={[Math.PI / 2, 0, 0]}
              position={[x, 4, i * 4]}
              scale={[3, 1, 1]}
            />
          ))}
        </group>
      </group>
      {/* Sides */}
      <Lightformer
        intensity={4}
        rotation-y={Math.PI / 2}
        position={[-5, 1, -1]}
        scale={[20, 0.1, 1]}
      />
      <Lightformer
        rotation-y={Math.PI / 2}
        position={[-5, -1, -1]}
        scale={[20, 0.5, 1]}
      />
      <Lightformer
        rotation-y={-Math.PI / 2}
        position={[10, 1, 0]}
        scale={[20, 1, 1]}
      />
      <Float speed={5} floatIntensity={2} rotationIntensity={2}>
        <Lightformer
          form="ring"
          color="red"
          intensity={1}
          scale={10}
          position={[-15, 4, -18]}
          target={[0, 0, 0]}
        />
      </Float>
      <mesh scale={100}>
        <sphereGeometry args={[1, 64, 64]} />
        <LayerMaterial side={THREE.BackSide}>
          <Color color="#444" alpha={1} mode="normal" />
          <Depth
            colorA="blue"
            colorB="black"
            alpha={0.5}
            mode="normal"
            near={0}
            far={300}
            origin={[100, 100, 100]}
          />
        </LayerMaterial>
      </mesh>
    </>
  );
}
