import { AnimatePresence, motion } from "framer-motion";
import profile from "../assets/profile.png";
import { BsFillLaptopFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";
import { FaFacebook , FaLinkedin  } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Social_info = [
  {
    id: 1,
    img: <FaLinkedin size={20} />,
    name: "Linkedin",
    link: "https://www.linkedin.com/in/gihan-sudeepa/",
  },
  {
    id: 2,
    img: <MdEmail  size={20} />,
    name: "Email",
    link: "mailto:gihansudeeparandimal@gmail.com",
  },
  {
    id: 3,
    img: <FaFacebook size={20} />,
    name: "Facebook",
    link: "https://web.facebook.com/gihan.sudeepa.9/",
  },
  {
    id: 4,
    img: <RiInstagramFill size={20} />,
    name: "Facebook",
    link: "https://www.instagram.com/gihan_sudeepa/",
  },

  {
    id: 5,
    img: <FaSquareXTwitter  size={20} />,
    name: "Facebook",
    link: "https://twitter.com/sudeepa_gihan",
  },

]

const Social = () => {
  return (
    <div className="absolute z-10 bottom-0 font-michroma w-full ">
      <motion.div
        className="mb-5"
        variants={{
          hidden: { opacity: 0, x: 0 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 1.5,
        }}
        //className=" flex-col items-center justify-center  rounded-[10px] mt-10 p-8  backdrop-blur-sm bg-white/60  inset-0 flex  "
      >
        <section
          id="skills"
          className="flex flex-col items-center justify-center gap-3  relative overflow-hidden  "
        >
          <div className="bg-white/10 backdrop-blur-sm rounded-full  p-2 flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
            {Social_info.map( soical => (
              <SkillDataProvider
                key={soical.id}
                img={soical.img}
                link={soical.link}
                index={soical.id}
              />
            ))}
          </div>
        </section>
      </motion.div>
    </div>
  );
};

const SkillDataProvider = ({ img, index , link }) => {
  const imageVariants = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { delay: (index + 1) * 0.5, duration: 0.5 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: (index + 1) * 0.5, duration: 0.5 },
    },
  };

  return (
<a href={link}>
      <motion.div
        initial="hidden"
        variants={imageVariants}
        animate="visible"
        custom={index}
        className="flex z-40 flex-col  relative items-center justify-center p-1 backdrop-blur-sm   rounded-full"
      >
{img}
      </motion.div>
</a>
  );
};
export default Social;
