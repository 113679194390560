export const project_data = [
  {
    id: 1,
    img: "p-happy.png",
    name: "Happy tails",
    value:
      "Itp Project Happy Tails is a project developed using the MERN (MongoDB, Express.js, React.js, Node.js) stack. It serves as an online platform for managing a private pet shelter located in Kandy, Sri Lanka.",
    short: "sadasd sad asdas dasdas das ",
    skills: ["React", "Node", "Express", "MongoDB"],
    links: [
      {
        name: "Github",
        link: "https://github.com/GIHAA/itp-project-happy-tails",
      },
      { name: "Live Site", link: "https://happytails-six.vercel.app/" },
    ],
  },
  {
    id: 2,
    img: "p-task.png",
    name: "Task Manger",
    value:
      "The task management application is a full-stack project created using Next.js and Spring Boot, incorporating role-based authentication and integrated security, with the utilization of JWT (JSON Web Token) for enhanced authentication mechanisms",
    short: "sadasd sad asdas dasdas das ",
    skills: ["React", "Springboot", "Azure", "Vite"],
    links: [
      {
        name: "Github",
        link: "https://github.com/GIHAA/task-management-application",
      },
      {
        name: "Live Site",
        link: "https://task-management-application-pearl.vercel.app/",
      },
    ],
  },
  {
    id: 3,
    img: "p-snow.png",
    name: "Snow Ball",
    value:
      "2D platform runner android game made using UNITY, which has over 50+ downloads on playstore",
    skills: ["C#", "Android"],
    links: [
      {
        name: "Play Store",
        link: "https://play.google.com/store/apps/details?id=com.DefaultCompany.Snowball&pcampaignid=web_share",
      },
    ],
  },
  {
    id: 4,
    img: "p-bodima.png",
    name: "Bodima",
    value:
      "Bodima is an app designed to help people find accommodation and connect with sellers. It allows users to search through listings and easily contact sellers to inquire about available accommodations and related items like furniture, food, and groceries. Simplify your search for accommodation and connect with sellers effortlessly using Bodima.",
    skills: ["Android", "Kotlin"],
    links: [
      { name: "Github", link: "https://github.com/GIHAA/mad-project-bodima" },
    ],
  },
  {
    id: 5,
    img: "p-scrapie.png",
    name: "Scrapie",
    value:
      "Scrapie is a mobile app enabling users to repurpose and recycle products nearing the end of their lifecycles. Users can scan items for recycling, repairing, or giving away, promoting sustainable consumption",
    skills: ["Android", "React native", "Firebase", "Tensorflow"],
    links: [{ name: "Github", link: "https://github.com/GIHAA/scrapie" }],
  },
  {
    id: 6,
    img: "p-egg.png",
    name: "Egg Incubator ",
    value:
      "Automatic Egg Incubator using NodeMCU ESP32 is an innovative and technologically advanced device designed to facilitate the automated hatching of eggs. This intelligent incubator leverages the capabilities of the NodeMCU ESP32, a powerful and versatile microcontroller, to create a sophisticated and user-friendly system.",
    skills: ["IOT", "Arduino", "NodeMCU", "ESP32"],
    links: [
      { name: "Github", link: "https://github.com/GIHAA/egg-incubator" },
    ],
  },
  {
    id: 7,
    img: "p-pro.JPG",
    name: "Procure Express",
    value:
      "The project aims to create an online and mobile solution for site managers, streamlining access to a business's purchase order system. This tool facilitates the direct creation and placement of purchase orders, inquiry into past orders, and tracking of product deliveries for efficient bill payment",
      skills: ["Android", "React native", "SpringBoot"],
    links: [
      { name: "Github", link: "https://github.com/GIHAA/csse-procurement-management-system-mobile" },
    ],
  },
  {
    id: 8,
    img: "p-tasty.png",
    name: "Tasty Delights",
    value:
      "A completely functional web application for a bakery that manages every step of the process, from food preparation to food delivery to consumers",
      skills: ["JSP", "CSS", "Servlet" , "JavaScript" , "MySQL" ],
    links: [
      { name: "Github", link: "https://github.com/GIHAA/tasty-delights" },
    ],
  },
]