import { motion } from "framer-motion";

const Heading = () => {
  return (
    <div className="z-40 absolute font-michroma top-10 right-10 ml-[10px] md:ml-[0px] text-[40px] md:text-[50px] ">
      <motion.div
        variants={{
          hidden: { opacity: 0, x: -75 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 1.5,
        }}
      >
        GIHAN SUDEEPA{" "}
      </motion.div>
      <motion.h1
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 1.5,
        }}
        className="uppercase text-gray-300 text-[17px] md:text-[25px]"
      >
        I edit text files for a living{" "}
      </motion.h1>
    </div>
  );
};

export default Heading;
